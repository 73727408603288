/* Base styling to match Apple's aesthetic */
body, html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #333; /* Apple typically uses near-black for text */
  background-color: #f2f2f7; /* A light grey background is common in Apple designs */
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal overflow */
}


/* Container styling, adding more whitespace and structure */
.projects-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 120px; /* Apple designs favor generous padding */
  border-radius: var(--64px) !important;
  padding-top: 10rem;
}

/* For responsive design, Apple favors full-width containers on mobile */
@media (max-width: 600px) {
  .projects-container {
    padding: 60px 20px; /* Add horizontal padding */
  }

  .projects-project {
    width: 100%; /* Full width on smaller screens */
  }
}

/* Title styling, bold and centered, often with larger font sizes */
.title {
  color: #333;
  font-size: 2em; /* Larger font size */
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem; /* Spacing after title */
}

/* Subtitle styling, informative with lighter font-weight */
.subtitle {
  font-size: 1em;
  line-height: 1.5em;
  color: #666; /* Subdued text color for subtitles */
  width: 80%;
  margin: 0 auto 2em; /* Centered with bottom margin */
  text-align: center;
}

/* Footer styling to be consistent with the overall minimalistic design */
.page-footer {
  position: block;
  text-align: center; /* Center content in footer */
}

.page-footer::before {
  content: "";
  display: block;
  margin: 2em auto; /* Center the border */
  width: 60%; /* Short border width */
  /* border-top: 2px solid #d1d1d6; Light border color */
}

