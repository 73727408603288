:root {

	--color-green500: hsla(172, 95%, 18%, 1);
	/* ------- colors ------- */
	/* --primary-color: hsla(213, 100%, 30%, 1); */
	--primary-color: #005ccc;
	/* --secondary-color: #444559; */
	/* --secondary-color: #5e7183; */
	--secondary-color: #524F54;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #33A1FD;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;

	/* --font-body: clamp(1rem, .8852rem + .4898vw, 1.375rem); */
	/* --------------------- */
}
