@import "../../../data/styles.css";

.footer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px !important;
	margin: 0 auto; /* Center the footer */
	text-align: left;
  }
  
.footer-grid {
display: flex;
justify-content: center; /* This ensures content within the grid is centered */
align-items: center;
width: 100%; /* Full width to the parent */
max-width: 55%; /* Adjust this value based on your design */
margin: 0 auto; /* Center the grid container */
}


.footer-credits,
.footer-links {
  display: flex;
  /* flex-direction: column; */
  /* margin-right: 20px; */
  /* text-align: left; */
}

.footer-link a {
	text-decoration: none;
	color: var(--secondary-color);
	font-weight: bold;
	display: block; /* Ensure links take up their own line and align left */
  }

.footer-credits-text{
	color: var(--primary-color);
	text-decoration: none;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	
}

.footer-section, .footer-link {
	padding-left: 0; /* Remove padding if any */
	margin-left: 0; /* Remove margin if any */
	/* text-align: left; Align text to the left */
	justify-content: right;
}

.footer-link a,
.footer-colophon a {
  text-decoration: none;
  color: var(--secondary-color);
  padding: calc(1rem - 6px) 0;
  font-weight: bold;
  
  
}

.footer-link a:hover,
.footer-colophon a:hover {
  color: var(--link-color);
}

.song-title {
  display: block;
  font-size: 80%;
  font-weight: normal;


}

.footer-link .elsewhere div {
	margin-bottom: 10px;
	
  }
  
  .footer-link .elsewhere div:last-child {
	margin-bottom: 0;
	
  }

.footer-links .footer-link {
  margin-bottom: 20px;
}

.footer-links .elsewhere div{
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 10px;
  text-align: left;
}


@media (max-width: 600px) {
  .footer {
    flex-direction: column;
  }

  .footer-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer-credits,
  .footer-links {
    align-items: center;
  }
}
