@import "/src/data/styles.css";


@keyframes animateGlow {
    0% {
        background-position: 0% 50%;
    }
    20% {
        background-position: 25% 75%;
    }
    40% {
        background-position: 75% 25%;
    }
    60% {
        background-position: 50% 50%;
    }
    80% {
        background-position: 25% 25%;
    }
    100% {
        background-position: 0% 50%;
    }
}


  

html, body {
    overflow-x: hidden; /* Hide horizontal overflow */
    max-width: 100vw; /* Prevent width from exceeding viewport */
    box-sizing: border-box; /* Include padding and border in element's total width */
	
}

:root {
	--color-blue24:#0053b8;
	--color-beige: hsla(36, 31%, 90%, 1);
	--color-background:#d7d5d5;
	--font-h1: clamp(3.2rem, .5692rem + 8.238vw, 13.75rem);
	/* --font-body: clamp(1.5rem, .8852rem + .4898vw, 1.375rem); */
    --font-body: clamp(1rem, .8852rem + .4898vw, 1.375rem);

	--bodyFont: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	--spacing-xxl: max(130px, calc(130px +(260 - 130)*((100vw - 375px) /(1600 - 375))));
    --spacing-xl: max(var(--64px), calc(var(--64px) +(128 - 64)*((100vw - 375px) /(1600 - 375))));
    --spacing-l: max(var(--42px), calc(var(--42px) +(84 - 42)*((100vw - 375px) /(1600 - 375))));
    --spacing-m: max(var(--24px), calc(var(--24px) +(48 - 24)*((100vw - 375px) /(1600 - 375))));
    --spacing-s: max(var(--21px), calc(var(--21px) +(42 - 21)*((100vw - 375px) /(1600 - 375))));
    --spacing-xs: max(var(--14px), calc(var(--14px) +(28 - 14)*((100vw - 375px) /(1600 - 375))));
    --spacing-xxs: max(calc(var(--14px) / 2), calc((var(--14px) / 2) +(14 - 7)*((100vw - 375px) /(1600 - 375))));
  }


::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background-color: #383636;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgb(0, 0, 0);
}
  
.page-content {
	background-color: var(--color-background);
	background-image: var(--color-gradient);
	position: relative;
	box-sizing: border-box;
	height:100%;
	background:linear-gradient(120deg, #0080ffb8,#dfdfdf47,#699dd9d1,#cecdcd65);

	background-size: 600% 600%;
	animation: animateGlow 150s ease infinite;
	-webkit-backdrop-filter: blur(3px);

}

.page-content:before,
.page-content:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	/* width: calc((100% - 1200px) / 2); */
	width:fit-content;
	/* background-color: #eaeaea; */
	
}

.page-content:before {
	left: 0;
	/* z-index: 1; */
}

.page-content:after {
	right: 0;
	/* z-index: 1; */
}

.content-wrapper {
    max-width: 100%; /* Allow content wrapper to fill available width */
    margin: 0 auto;
    padding: 0 20px; /* Add padding instead of using width calculations */
    box-sizing: border-box; /* Include padding in the width */
	z-index: 2;
}


.title {
	color: var(--primary-color);
	/* font-family: var(--secondary-font); */
	font-family: 'Acorn Semi-Bold', sans-serif;
	font-size: var(--font-h1);
	font-weight: 700;
	width: 100%;
	text-align: center;
}

.subtitle {
	/* padding-top: 10px; */
	color: var(--secondary-color);
	font-family:var(--bodyFont);
	font-size: var(--font-body) !important;
	line-height: 28px;
	font-feature-settings: "liga";
	width: 50%;
	margin-top: 25px;
	letter-spacing: -.3px;
	font-size: 22px !important;
	/* line-height: 160%; */
}

.page-footer {
	position: relative;
}

.page-footer::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	/* border-top: 2px solid var(--tertiary-color); */
}

@media (max-width: 1270px) {
	.page-content:before,
	.page-content:after {
		width: calc((100% - 90%) / 2);
	}

	.content-wrapper {
		max-width: 90%;
		padding-left: 10%;
		padding-right: 10%;
	}
}

@media (max-width: 1024px) {
	.page-content:before,
	.page-content:after {
		width: calc((100% - 950px) / 2);
	}

	.content-wrapper {
		max-width: 980px;
	}
}

@media (max-width: 800px) {
	.content-wrapper {
		max-width: 90%;
		margin: 0 auto;
		padding: 0 10px;
	}

	.page-content:before,
	.page-content:after {
		display: none;
	}

	.title {
		width: 100%;
	}

	.subtitle {
		width: 100%;
	}
}
