.articles-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 10rem;
	width: 100%;

}

.articles-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}
/* 
.articles-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
	width: 100vw !important;
} */
/* 
.articles-article {
	padding-top: 0px;
	padding-left: 35px;
	padding-bottom: 20px;
	border-left: 2px solid #f4f4f5;
	width: 100%;
} */

.portfolio-buttons{
	display: flex;
	justify-content: center;
}

.image-gallery{
	width:90%;

	margin: 0 auto;
}

@media (max-width: 1024px) {
	.articles-title {
		width: 100% !important;
	}

	.articles-subtitle {
		width: 100% !important;
	}
}
