@import "../../../data/styles.css";

.nav-container {
	margin: 0 !important;
	display: flex;
	justify-content: center !important;
	align-items: center !important;
	
}

.navbar {
	display: flex;
	justify-content: center !important;
	align-items: center;
	position: fixed;
	top: 2.5%; /* Adjusted to percentage */
	width: 100%;
	z-index: 100;
}

.nav-background {
	box-sizing: border-box;
	width:60%;
	max-width: 500px;
	height:50px;
	/* padding: 1% 0; Padding top and bottom */
	padding: calc(1.25rem - 10px);
	background: transparent;
	transition: background-color 0.3s ease-in-out;
	transition: all .2s linear;
	border-radius: 50px; /* Adjusted radius */
	display: flex;
	justify-content: space-evenly; /* Even spacing of items */
	align-items: center;
	overflow: hidden;
}

.nav-background.scrolled {
	background: rgba(244, 243, 240, 0.8); /* Semi-transparent white on scroll */
	backdrop-filter: blur(20px) saturate(1.7); /* Adjusted to be based on the viewport width */

}

.nav-list {
	display: flex;
	justify-content: space-around; /* Even distribution of space */
	align-items: center;
	list-style: none;
	padding: 0;
	width: 100%;
	margin: 0; /* Ensure list takes full width with no extra margin */
}

.nav-item {
	font-weight: bold !important;
	/* margin: 0 0.5; Reduced margin in percentage */
	white-space: nowrap;
}

.nav-item.active a {
	position: relative;
	background: hsla(0, 0%, 100%, 0.5);
	padding-bottom: 6px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 6px;
}

.nav-item.active a::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: -1;
}

.nav-item.active a span {
	position: relative;
	z-index: 2;
}

.nav-item a {
    text-decoration: none;
    color: var(--primary-color);
    padding: calc(1.25rem - 5px);
    border-radius: 25px; /* Optional: Adds rounded corners to all nav items */
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 600px) {
    .navbar {
        font-size: 80%; /* Further reduce font size for smaller screens */
		position: fixed;
    }

    .nav-background {
        width: 80%; /* Increase width to provide more space for nav items */
        /* position: sticky; Keep navbar at the top */
		padding: 10px 10px;
    }

    .nav-item a {
        padding: 8px 10px; /* Reduce padding to fit more items */
    }
}

