.contact-subtitle {
	width: 100% !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-top: 10rem;
	height: 100vh;
}


.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.contact-form-section{
	display:block;
	width: 60%;
	margin: auto !important;
	height:100%;
	background: hsla(0, 0%, 100%, 0.5);
	backdrop-filter: blur(10px) saturate(1.3);
	border-radius: 4rem;
    padding: var(--spacing-l) 0 calc(var(--spacing-l) + 2.652rem);
    margin: var(--spacing-xs) auto calc(var(--spacing-l) - 20px);
}