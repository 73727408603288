.embla {
  width: 90%;
  margin: auto;
  --slide-height: 30rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-spacing-sm: 1.6rem;
  --slide-size-sm: 50%;
  --slide-spacing-lg: 2rem;
  --slide-size-lg: calc(100% / 5);
}
.embla__viewport {
  overflow: hidden;
  /* width:90%; */

}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin: 0 auto; 
  width: 100%; /* Ensure this matches the viewport width if necessary */
  margin-left: calc(var(--slide-spacing) * -1);
}

@media (min-width: 750px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-sm) * -1);
  }
}
@media (min-width: 1200px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-lg) * -1);
  }
}

.embla__slide {
  min-width: 0;
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
}

@media (min-width: 750px) {
  .embla__slide {
    flex: 0 0 var(--slide-size-sm);
    padding-left: var(--slide-spacing-sm);
  }
}
@media (min-width: 1200px) {
  .embla__slide {
    flex: 0 0 var(--slide-size-lg);
    padding-left: var(--slide-spacing-lg);
  }
}
.embla__slide__image {
  border-radius: 40px;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
}
.embla__controls {
  display: block;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
  justify-content: center;
}
.embla__buttons {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: grey;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}

.line-slider {
  /* width: 100%; Ensure line slider takes full width */
  padding: 20px 0; /* Maintain padding for better touch area */
  display: flex; /* Use flex to help with inner alignment if needed */
  justify-content: center; /* Center any inner elements if applicable */
  align-items: center; /* Center any inner elements if applicable */
}

.line-slider__input {
  -webkit-appearance: none; /* Override default browser styles */
  appearance: none;
  width: 100%;
  height: 2px; /* Line thickness */
  background: var(--tertiary-color); /* Line color */
  outline: none;
  opacity: 0.7; /* Line opacity */
  transition: opacity 0.2s; /* Smooth transition for hover effect */
}

.line-slider__input:hover {
  opacity: 1; /* Full opacity on hover */
}

.line-slider__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* Thumb width */
  height: 20px; /* Thumb height, same as width to create a circle */
  background: #204ca4; /* Thumb color */
  border-radius: 50%; /* Make the thumb circular */
  cursor: pointer;
  transition: transform 0.2s ease-in-out; /* Smooth scaling transition */
}

.line-slider__input::-webkit-slider-thumb:hover {
  transform: scale(1.1); /* Slightly increase size on hover for a smoother feel */
}

.line-slider__input::-moz-range-thumb {
  width: 20px; /* Thumb width */
  height: 20px; /* Thumb height, same as width to create a circle */
  background: #204ca4; /* Thumb color */
  border-radius: 50%; /* Make the thumb circular */
  cursor: pointer;
  transition: transform 0.2s ease-in-out; /* Smooth scaling transition */
}

.line-slider__input::-moz-range-thumb:hover {
  transform: scale(1.1); /* Slightly increase size on hover for a smoother feel */
}

/* For Microsoft Edge */
.line-slider__input::-ms-thumb {
  width: 20px; /* Thumb width */
  height: 20px; /* Thumb height, same as width to create a circle */
  background: #204ca4; /* Thumb color */
  border-radius: 50%; /* Make the thumb circular */
  cursor: pointer;
  transition: transform 0.2s ease-in-out; /* Smooth scaling transition */
}

.line-slider__input:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 5px rgba(32,76,164,0.2); /* Focus state with a subtle outer glow */
}

.line-slider__input:focus::-moz-range-thumb {
  box-shadow: 0 0 0 5px rgba(32,76,164,0.2); /* Focus state with a subtle outer glow */
}

.line-slider__input:focus::-ms-thumb {
  box-shadow: 0 0 0 5px rgba(32,76,164,0.2); /* Focus state with a subtle outer glow */
}
